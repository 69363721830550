import { useState, useEffect, useCallback } from 'react';
import { Layout } from '@shopify/polaris';
import Table from '../components/Table/Table';
import ChartBar from '../components/ChartBar';
import Loading from '../components/Loading';
import TotalNumbers from '../components/TotalNumbers/TotalNumbers';
import ToggleButtonGroup from '../components/ToggleButtonGroup/ToggleButtonGroup';
import { getCardData, getStatus, getSummaryData, ReturnValueData, ReturnVolumeData, getBackendUrl } from '../utils/utils';
import Error from '../components/Error';
import Card from '../components/Card/Card';
import Onboarding from '../components/Onboarding/Onboarding';
import DateRange from '../components/DateRange/DateRange';
import HeaderContent from '../components/HeaderContent/HeaderContent';
import Empty from '../components/Empty/Empty';
import styles from '../styles/Home.module.css';

const API_TYPES = {
  PRODUCT_TITLE: 'product_title',
  PRODUCT_TYPE: 'product_type',
  VENDOR: 'vendor',
  TAG: 'tags',
}

// If tags is empty add custom tag message
const processTagsData = (data) => {
  return data.map(x => {
    if (!x.tags) {
      x.tags = '(no tags)';
    }
    return x
  });
}

function Home() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [isSelected, setIsSelected] = useState('value');
  const [summaryData, setSummaryData] = useState(null);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState('lastMonth');
  const [productTitleData, setProductTitleData] = useState(null);
  const [productTypeData, setProductTypeData] = useState(null);
  const [vendorData, setVendorData] = useState(null);
  const [tagsData, setTagsData] = useState(null);

  const initialPagination = {
    limit: 10,
    offset: 0,
    total: 10,
  };

  const [titleDataPagination, setTitleDataPagination] = useState(initialPagination);
  const [typeDataPagination, setTypeDataPagination] = useState(initialPagination);
  const [vendorDataPagination, setVendorDataPagination] = useState(initialPagination);
  const [tagsDataPagination, setTagsDataPagination] = useState(initialPagination);

  const buttonGroup = [
    {
      label: 'Refund value',
      selected: isSelected === 'value',
      onClick: () => setIsSelected('value'),
    },
    {
      label: 'Refund volume',
      selected: isSelected === 'volume',
      onClick: () => setIsSelected('volume'),
    },
  ];

  const formatAndSetChartData = (chartData, currency) => {
    const {
      formattedData,
      options,
    } = isSelected === 'value' ? ReturnValueData(chartData, currency) : ReturnVolumeData(chartData);
    setChartData(formattedData);
    setChartOptions(options);
  };

  const setUserLocalStorage = () => {
    const storage = window.localStorage;
    storage.setItem('isNewUser', 'false');
    setShowOnboarding(false);
  };


  const setDataFromApi = (res, index, setData, setPagination) => {
    setData(res[index] && res[index].data ? res[index].data : null);
    setPagination(res[index] && res[index].data ? {
      offset: parseInt(res[index].data.meta.offset),
      limit: parseInt(res[index].data.meta.limit),
      total: parseInt(res[index].data.meta.total_results),
    } : initialPagination);
  }
  async function fetchData(dateRange) {
    setLoading(true);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();
    const url = getBackendUrl('shopify-returns/report');

    try {
      const res = await Promise.all([
        getSummaryData(url, userTimezone, dateRange),
        getCardData(url, userTimezone, dateRange, API_TYPES.PRODUCT_TYPE, typeDataPagination),
        getCardData(url, userTimezone, dateRange, API_TYPES.PRODUCT_TITLE, titleDataPagination),
        getCardData(url, userTimezone, dateRange, API_TYPES.VENDOR, vendorDataPagination),
        getCardData(url, userTimezone, dateRange, API_TYPES.TAG, tagsDataPagination),
      ]);
      const summaryData = res[0].data;
      setSummaryData(summaryData);

      setDataFromApi(res, 1, setProductTypeData, setTypeDataPagination);
      setDataFromApi(res, 2, setProductTitleData, setTitleDataPagination);
      setDataFromApi(res, 3, setVendorData, setVendorDataPagination);
      setDataFromApi(res, 4, setTagsData, setTagsDataPagination);


      formatAndSetChartData(summaryData.data?.item_refunds_by_day, summaryData.data?.retailer.currency);
      setLoading(false);
      setError(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
    }
  }

  const onDateRangeChange = useCallback((selected) => {
    setSelectedDateRange(selected);
    selected !== 'last60'
      ? fetchData(selected)
      : fetchData(undefined);
  }, []);


  const onPaginationChange = async (pagination, type) => {
    type === API_TYPES.PRODUCT_TITLE ?
      setTitleDataPagination(pagination)
      : type === API_TYPES.PRODUCT_TYPE ?
      setTypeDataPagination(pagination)
      : type === API_TYPES.VENDOR ?
      setVendorDataPagination(pagination)
      : type === API_TYPES.TAG ?
      setTagsDataPagination(pagination) :
      null;

    try {
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();
      const url = getBackendUrl('shopify-returns/report');

      const data = await getCardData(url, userTimezone, selectedDateRange, type, pagination);
      switch(type) {
        case API_TYPES.PRODUCT_TITLE: {
          setProductTitleData(data.data);
          return;
        };

        case API_TYPES.PRODUCT_TYPE: {
          setProductTypeData(data.data);
          return;
        };

        case API_TYPES.VENDOR: {
          setVendorData(data.data);
          return;
        };

        case API_TYPES.TAG: {
          setTagsData(data.data);
          return;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  async function fetchJobStatus() {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const jobId = urlParams.get('job');
    const url = getBackendUrl(`shopify-returns/jobs/${jobId}/status`);

    try {
      const status = await getStatus(url);
      if (status === 'failed') {
        setLoading(false);
        setError(true);
      } else {
        fetchData('lastMonth');
      }

    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
    }
  }


  useEffect(() => {
    const storage = window.localStorage;
    if (storage.getItem('isNewUser') == null) {
      setShowOnboarding(true);
    } else setShowOnboarding(false);

    if (process.env.NODE_ENV === 'development') {
      fetchData('lastMonth');
    } else {
      fetchJobStatus();
    }

  }, []);


  useEffect(() => {
    if (summaryData) {
      formatAndSetChartData(summaryData.data?.item_refunds_by_day, summaryData.data?.retailer.currency);
    }
  }, [isSelected]);

  return (
    <div>
      <div className={styles.headerContainer} style={error || loading ? { paddingBottom: '1rem' } : null}>
        <div className={styles.pageWrapper}>
          <div className={styles.titleContainer} style={summaryData == null ? { paddingBottom: '2rem' } : null}>
            <img src='/logo.svg' className={styles.logo} alt='ReturnBear Logo' />
            <HeaderContent
              error={error}
              loading={loading}
              showOnboarding={showOnboarding}
              summaryData={summaryData}
            />
            <DateRange onChange={(v) => onDateRangeChange(v)} selected={selectedDateRange} disabled={loading} />
          </div>
          {!loading && !error && showOnboarding ? (
              <Layout>
                <Layout.Section>
                  <div className={styles.container}>
                    <h2 className={styles.onboardingMsg}>Insights into your store's refunds</h2>
                  </div>
                </Layout.Section>
              </Layout>
            ) :
            !loading && !error && summaryData &&
            <TotalNumbers summaryStats={summaryData.data?.summary_stats} />
          }
        </div>
      </div>
      <div className={styles.pageWrapper}>
        {loading ?
          (<Loading />)
          : showOnboarding ?
            (<Onboarding onClick={setUserLocalStorage} />)
            : error ? (<Error />)
              : summaryData?.data?.summary_stats.total_volume_refunded === 0 ?
                (<Empty />)
                : (<>
                    <Layout>
                      <Layout.Section>
                        <Card style={{ padding: '2rem 0 1rem', borderRadius: '0 0 0.625rem 0.625rem' }}>
                          <ToggleButtonGroup buttonGroup={buttonGroup} />
                          {isSelected === 'value' ? (
                            <ChartBar key='value' id='value' data={chartData} options={chartOptions} />
                          ) : (
                            <ChartBar key='volume' id='volume' data={chartData} options={chartOptions} />
                          )}
                        </Card>
                      </Layout.Section>
                    </Layout>

                    <div className={styles.row}>
                      {productTitleData && <div className={styles.col}>
                        <Card sectioned subdued title='Item'>
                          <Table
                            data={productTitleData.data}
                            pagination={titleDataPagination}
                            type={API_TYPES.PRODUCT_TITLE}
                            onPaginationChange={onPaginationChange}
                          />
                        </Card>
                      </div>}

                      {productTypeData && <div className={styles.col}>
                        <Card sectioned subdued title='Type'>
                          <Table
                            data={productTypeData.data}
                            pagination={typeDataPagination}
                            type={API_TYPES.PRODUCT_TYPE}
                            onPaginationChange={onPaginationChange}
                          />
                        </Card>
                      </div>}

                      {vendorData && <div className={styles.col}>
                        <Card sectioned subdued title='Vendor'>
                          <Table
                            data={vendorData.data}
                            pagination={vendorDataPagination}
                            type={API_TYPES.VENDOR}
                            onPaginationChange={onPaginationChange}
                          />
                        </Card>
                      </div>}

                      {tagsData && <div className={styles.col}>
                        <Card sectioned subdued title='Tag'>
                          <Table
                            data={processTagsData(tagsData.data)}
                            pagination={tagsDataPagination}
                            type={API_TYPES.TAG}
                            onPaginationChange={onPaginationChange}
                          />
                        </Card>
                      </div>}
                    </div>

                  </>
                )}
      </div>

      <footer>
        <p className={styles.footer}>
          <a href="mailto:analytics-app@returnbear.com">
            Support
          </a>
          {" | "}
          <a
            href='https://docs.google.com/document/d/1agt_f2wTTCyFO1UpVTlQ2oyTU-Z_YREX9eZzOK-0Mlc' target='_blank'>Privacy
            Policy</a>
            <br/>
          © Copyright ReturnBear 2021. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default Home;
