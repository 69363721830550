import React from 'react';
import { EmptyState, Layout, Spinner } from '@shopify/polaris';

const img = '/loading.svg';

const Loading = () => {
  return (
    <Layout>
      <EmptyState heading="Loading your reports." image={img}>
        <div style={{ width: '100%', marginTop: 40 }}>
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 20 }}>
            <Spinner accessibilityLabel="Loading data" size="large" color="teal" />
          </div>
          <p style={{ display: 'inline-block' }}>Analyzing your orders...</p>
        </div>
      </EmptyState>
    </Layout>
  );
};

export default Loading;
