import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@shopify/polaris';
import styles from './DateRange.module.css';

const DateRange = ({ selected = 'last60', onChange, disabled = true }) => {

  const options = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 days', value: 'last7' },
    { label: 'Last 15 days', value: 'last15' },
    { label: 'Last 30 days', value: 'last30' },
    { label: 'Last 60 days', value: 'last60' },
    { label: 'Last Month', value: 'lastMonth' },
  ];

  return (
    <div className={styles.selectWrappers}>
      <Select
        className={styles.select}
        label='Date range'
        labelHidden={true}
        options={options}
        onChange={v => onChange(v)}
        value={selected}
        disabled={disabled}
      />
    </div>
  );
};

DateRange.propTypes = {
  selected: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateRange;
