import React from 'react';
import { EmptyState, Layout} from '@shopify/polaris';

const img = '/error.svg';

const Error = () => {
  return (
    <Layout>
      <EmptyState heading="Oops something went wrong." image={img}>
        <div style={{ width: '100%', marginTop: 40 }}>
          <p style={{ display: 'inline-block' }}>Please refresh.</p>
        </div>
      </EmptyState>
    </Layout>
  );
};

export default Error;
