import React from 'react';
import styles from './HeaderContent.module.css';

const HeaderContent = ({ showOnboarding, loading, error, summaryData }) => {
  return showOnboarding ?
    <h1 className={styles.title}>
      Welcome to ReturnBear Analytics
    </h1>
    : !loading && !error && summaryData ?
      <>
        <h1 className={styles.title}>
          {summaryData.data?.retailer?.name} Refunds
        </h1>
        <span
          className={styles.titleBadge}>Currency ${summaryData.data?.retailer?.currency}
        </span>
      </>
      : <h1 className={styles.title}>
        ReturnBear Analytics
      </h1>;
};


export default HeaderContent
