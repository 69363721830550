import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const ChartBar = ({ id, data, options }) => {

  return (
    <div>
      <Bar
        id={id}
        data={data}
        width={400}
        height={300}
        options={options}
      />
    </div>
  );
};

ChartBar.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default ChartBar;
