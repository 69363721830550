import axios from 'axios';

export const getStatus = async (url) => {
  const { data } = await axios({
    method: 'get',
    url: url,
    withCredentials: true,

  });
  if (data.data.job.status === 'queued' || data.data.job.status === 'started') {
    setTimeout(await getStatus(url), 60000);
  } else return data.data.job.status;
};

export const getSummaryData = (url, userTimezone, dateRange) => {
  const { startDate, endDate } = getStartDateTime(dateRange);
  const dateTime = dateRange ? { start_dt: startDate, end_dt: endDate } : {};
  return axios({
    method: 'get',
    url: `${url}/summary`,
    withCredentials: true,
    params: {
      force: 1,
      timezone: userTimezone,
      limit: 500,
      ...dateTime,
    },
  });
};

export const getCardData = (url, userTimezone, dateRange, cardType, pagination) => {
  const { startDate, endDate } = getStartDateTime(dateRange);
  const dateTime = dateRange ? { start_dt: startDate, end_dt: endDate } : {};
  return axios({
    method: 'get',
    url: `${url}/cards/${cardType}`,
    withCredentials: true,
    params: {
      force: 1,
      timezone: userTimezone,
      offset: pagination.offset,
      limit: pagination.limit,
      ...dateTime,
    },
  });
};

export const ReturnValueData = (chartData, currency) => {
  const labels = chartData.map(d => new Date(d.day).toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
  }));
  const returnDataPoints = chartData.map(d => parseFloat(d.value_items_refunded).toFixed(2));
  const orderDataPoints = chartData.map(d => parseFloat(d.value_items_purchased).toFixed(2));

  return {
    formattedData: {
      labels,
      datasets:
        [
          {
            label: `Returns Value ${currency}`,
            data: returnDataPoints,
            backgroundColor: '#454DE7',
          },
          {
            label: `Orders Value ${currency}`,
            data: orderDataPoints,
            backgroundColor: '#00B19A',
          },
        ],
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      labels: {
        font: {
          family: 'IBM Plex Sans',
        },
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItems, data) {
            return `${data.datasets[tooltipItems.datasetIndex].label} $ ${tooltipItems.yLabel.toString()}`;
          },
          footer: function(tooltipItems, data) {
            return
          }
        },
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 12,
            maxRotation: 0,
            minRotation: 0,
            fontColor: '#404040',
            fontFamily: 'IBM Plex Sans Condensed, sans-serif',
          },
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return '$ ' + value;
            },
            fontFamily: 'IBM Plex Sans Condensed, sans-serif',
            fontColor: '#AFB0B0',
          },
        }],
      },
    },
  };
};

export const ReturnVolumeData = (chartData) => {
  const labels = chartData.map(d => new Date(d.day).toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
  }));
  const returnDataPoints = chartData.map(d => d.num_items_refunded);
  const orderDataPoints = chartData.map(d => d.num_items_purchased);
  const returnRateDataPoints = chartData.map(d =>  `${Math.round((d.return_rate + Number.EPSILON) * 100)}%`);

  return {
    formattedData: {
      labels,
      datasets: [
        {
          label: 'Returns Volume',
          data: returnDataPoints,
          returnRate: returnRateDataPoints,
          backgroundColor: '#454DE7',
        },
        {
          label: `Items Volume`,
          data: orderDataPoints,
          backgroundColor: '#00B19A',
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItems, data) {
            return `${data.datasets[tooltipItems.datasetIndex].label} ${tooltipItems.yLabel.toString()}`;
          },
          footer: function(tooltipItems, data){
            return `Return Rate ${data.datasets[0].returnRate[tooltipItems[0].index]}`
          }
        },
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            maxTicksLimit: 12,
            maxRotation: 0,
            minRotation: 0,
            fontColor: '#404040',
            fontFamily: 'IBM Plex Sans Condensed, sans-serif',
          },
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function(value, index, values) {
              return value;
            },
            fontFamily: 'IBM Plex Sans Condensed, sans-serif',
            fontColor: '#AFB0B0',
          },
        }],
      },
    },
  };
};


const formatDateTime = (dateTime) => dateTime.toISOString().split('T')[0];

const getStartDateTime = (selectedRange) => {
  //endDate
  const end = new Date();
  const endOfDay = new Date(end.setHours(12, 0, 0, 0));
  endOfDay.setDate(endOfDay.getDate() + 1);
  const endDateTime = new Date(endOfDay);
  const endDate = formatDateTime(endDateTime);

  //startDate
  const date = new Date();
  const startOfDay = date.setHours(12, 0, 0, 0);

  if (selectedRange === 'today') {
    const dateTime = new Date(startOfDay);
    const startDate = formatDateTime(dateTime);

    return { startDate, endDate };

  } else if (selectedRange === 'yesterday') {
    const setDateTime = new Date(date);
    setDateTime.setDate(setDateTime.getDate() - 1);
    const dateTime = new Date(setDateTime);
    const startDate = formatDateTime(dateTime);

    const yesterdayEnd = new Date();
    const yesterdayEndOfDay = new Date(yesterdayEnd.setHours(12, 0, 0, 0));
    yesterdayEndOfDay.setDate(yesterdayEndOfDay.getDate() + 1);
    const yesterdayEndDateTime = new Date(yesterdayEndOfDay);
    const yesterdayEndDate = formatDateTime(yesterdayEndDateTime);

    return { startDate, endDate: yesterdayEndDate };

  } else if (selectedRange === 'last7') {
    const setDateTime = new Date(date);
    setDateTime.setDate(setDateTime.getDate() - 7);
    const dateTime = new Date(setDateTime);
    const startDate = formatDateTime(dateTime);

    return { startDate, endDate };

  } else if (selectedRange === 'last15') {
    const setDateTime = new Date(date);
    setDateTime.setDate(setDateTime.getDate() - 15);
    const dateTime = new Date(setDateTime);
    const startDate = formatDateTime(dateTime);

    return { startDate, endDate };

  } else if (selectedRange === 'last30') {
    const setDateTime = new Date(date);
    setDateTime.setDate(setDateTime.getDate() - 30);
    const dateTime = new Date(setDateTime);
    const startDate = formatDateTime(dateTime);

    return { startDate, endDate };

  } else if (selectedRange === 'lastMonth') {
    const setDateTime = new Date();
    setDateTime.setDate(1);
    setDateTime.setMonth(setDateTime.getMonth() - 1);
    const startDateTime = new Date(setDateTime);
    const startDate = formatDateTime(startDateTime);

    const endMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth() + 1, 0);
    const endDateTime = new Date(endMonth);
    const endOfMonthDate = formatDateTime(endDateTime);

    return { startDate, endDate: endOfMonthDate };
  }
  return { startDate: '', endDate: '' };
};

/**
 * Get's the endpoint based on the env
 * @param {string} path
 * @returns string
 */
export const getBackendUrl = (path) => {
  const isStaging = window.location.href.includes('develop');
  return process.env.NODE_ENV === 'development' || isStaging
      ? `https://staging.api.returnbear.com/${path}`
      : `https://analytics-api.returnbear.com/${path}`;
}
