import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.css';

const Card = ({ title, children, style }) => {
  return (
    <div className={styles.card} style={style}>
      {title && <h4 className={styles.title}>Refunds By <span>{title}</span></h4>}
      {children}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default Card;
