import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@shopify/polaris';
import ScaleBar from '../ScaleBar/ScaleBar';
import styles from './Table.module.css';

const Table = (
  {
    data = [],
    type,
    pagination,
    onPaginationChange,
  }) => {
  const [sortedRows, setSortedRows] = useState(null);

  const allReturnRates = data.map(row => Math.round((row.return_rate + Number.EPSILON) * 100));

  const initiallySortedRows = data.map(row => {
    const maxRate = Math.max(...allReturnRates);
    const returnRate = Math.round((row.return_rate + Number.EPSILON) * 100);
    return [
      `${row[type]}`,
      `${row.num_purchased}`,
      `${row.num_refunded}`,
      <div className={styles.row} value={returnRate}>
        <ScaleBar max={maxRate} value={returnRate} /> {returnRate}%
      </div>,

    ];
  });

  const rows = sortedRows ? sortedRows : initiallySortedRows;

  function sorting(rows, index, direction) {
    return [...rows].sort((rowA, rowB) => {
      const amountA = parseFloat(rowA[index].props.value);
      const amountB = parseFloat(rowB[index].props.value);
      return direction === 'descending' ? amountB - amountA : amountA - amountB;
    });
  }

  const handleSort = useCallback(
    (index, direction) => setSortedRows(sorting(rows, index, direction)),
    [rows],
  );

  const paginationComponent = () => {
    const disablePrev = pagination.offset === 0;
    const disableNext = pagination.limit >= pagination.total;
    return (
      <div className={styles.btnWrapper}>
        <button
          disabled={disablePrev}
          className={styles.btn}
          onClick={() => onPaginationChange({ ...pagination, offset: pagination.offset - pagination.limit }, type)}
        >
          ← Previous
        </button>
        <button
          disabled={disableNext}
          className={styles.btn}
          onClick={() => onPaginationChange({ ...pagination, offset: pagination.offset + pagination.limit }, type)}
        >
          Next →
        </button>
      </div>
    );
  };

  return (
    <DataTable
      columnContentTypes={['text', 'text', 'text', 'numeric']}
      headings={['', <span className={styles.th}> Purchased volume</span>,
        <span className={styles.th}>Returned volume</span>,
        <span className={styles.th}>Return rate</span>]}
      rows={rows}
      sortable={[false, false, false, true]}
      defaultSortDirection='descending'
      initialSortColumnIndex={4}
      onSort={handleSort}
      footerContent={paginationComponent()}
    />
  );
};

Table.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};

export default Table;
