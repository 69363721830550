import React from 'react';
import { Layout } from '@shopify/polaris';
import PropTypes from 'prop-types';
import styles from './TotalNumbers.module.css';

const TotalNumbers = (
  {
    summaryStats = {
      total_volume_refunded: 0,
      total_volume_purchased: 0,
      total_value_purchased: 0,
      total_value_refunded: 0,
    },
  }) => {

  const returnRate = ((summaryStats.total_volume_refunded / summaryStats.total_volume_purchased) * 100).toFixed(2) || 0;
  const returnValue = summaryStats.total_value_refunded || '0';
  const orderValue = summaryStats.total_value_purchased || '0';
  const returnVolume = summaryStats.total_volume_refunded || 0;
  const orderVolume = summaryStats.total_volume_purchased || 0;

  return (
    <Layout>
      <Layout.Section>
        {returnVolume !== 0
          ? <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.col}>
                <label className={styles.label}>Refund Rate</label>
                <h2 className={styles.h2Ratio}>{returnRate}%</h2>
              </div>
              <span className={styles.span}>=</span>
            </div>

            <div className={styles.rowBlock}>
              <div className={styles.col}>
                <label className={styles.label}>Refund Volume (items)</label>
              </div>

              <div className={styles.col}>
                <div className={styles.row}>
                  <h2 className={styles.h2}>{returnVolume}</h2>
                  <span className={styles.span}>/</span>
                  <h2 className={styles.h2}>{orderVolume}</h2>
                </div>
              </div>
            </div>

            <div className={styles.rowBlock}>
              <div className={styles.col}>
                <label className={styles.label}>Refund Value</label>
              </div>

              <div className={styles.col}>
                <div className={styles.row}>
                  <h2 className={styles.h2}>${returnValue}</h2>
                  <span className={styles.span}>/</span>
                  <h2 className={styles.h2}>${orderValue}</h2>
                </div>
              </div>
            </div>
          </div>
          : ''
        }
      </Layout.Section>
    </Layout>
  );
};

TotalNumbers.propTypes = {
  summaryStats: PropTypes.shape({
    total_volume_refunded: PropTypes.number,
    total_volume_purchased: PropTypes.number,
    total_value_purchased: PropTypes.string,
    total_value_refunded: PropTypes.string,
  }),
};

export default TotalNumbers;
