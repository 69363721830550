import React from 'react';
import Image from 'next/image';
import Card from '../Card/Card';
import styles from './Onboarding.module.css';

const Onboarding = ({ onClick }) => {
  const SCREENSHOT_SIZE = 250;
  return (
    <Card>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <p className={styles.p} style={{ marginBottom: '2rem' }}>
            👋🏻 Thanks for installing ReturnBear Analytics and welcome!
          </p>
          <p className={styles.p}>
            Let's face it, issuing refunds stinks. That's why we created a dashboard that lets you:
          </p>
          <ul className={styles.ul}>
            <li>
              <Image
                src="/onboarding/benefit-1.png"
                alt="Screenshot of refund rate"
                width={SCREENSHOT_SIZE}
                height={SCREENSHOT_SIZE}
              />
              <p className={styles['benefit-title']}>See the big picture with your refund rate</p>
              <p className={styles['benefit-description']}>
                Track your overall item refund rate is and track how much revenue it's costing you
              </p>
            </li>
            <li>
            <Image
                src="/onboarding/benefit-2.png"
                alt="Screenshot of refund trends"
                width={SCREENSHOT_SIZE}
                height={SCREENSHOT_SIZE}
              />
              <p className={styles['benefit-title']}>See return and refund trends over time</p>
              <p className={styles['benefit-description']}>
                Understand the quality of those big sales days by knowing how much of those items
                and dollars ended up returned and refunded
              </p>
            </li>
            <li>
            <Image
                src="/onboarding/benefit-3.png"
                alt="Screenshot of refund breakdowns by type"
                width={SCREENSHOT_SIZE}
                height={SCREENSHOT_SIZE}
              />
              <p className={styles['benefit-title']}>Dig deeper and answer the why</p>
              <p className={styles['benefit-description']}>
                See what's driving refunds for your store by looking at breakdowns by product title,
                type, and vendor
              </p>
            </li>
          </ul>

          <p className={styles.p}>
            If you like the app, have a suggestion or spot a bug, please reach out by contacting us
            at <a href="mailto:analytics-app@returnbear.com">analytics-app@returnbear.com</a>.
          </p>

          <button onClick={onClick} className={styles.btn}>
            Show Me My Refund Dashboard
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Onboarding;
