import React from 'react';
import { EmptyState, Layout } from '@shopify/polaris';
import styles from './Empty.module.css';

const img = '/product.svg';
const arrow = '/curve-arrow.svg';

const Empty = () => {
  return (
    <>
      <h3 className={styles.arrowTxt}>
        Select a different date range
        <img src={arrow} className={styles.arrow} alt="arrow" />
      </h3>
      <Layout>
        <EmptyState heading='Yay, you have no refunds!' image={img}>
          <div style={{ width: '100%', marginTop: 40 }}>
            <p style={{ display: 'inline-block' }}>Currently, your store has 0 refunds for the selected date range.</p>
          </div>
        </EmptyState>
      </Layout>
    </>
  );
};

export default Empty;
