import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScaleBar.module.css';

const ScaleBar = ({ max, value }) => {
  const width = (value / max) * 100;
  return (
    <div className={styles.scaleBarContainer}>
      <div className={styles.scaleBar} style={{ width: `${width}%` }} />
    </div>
  );
};

ScaleBar.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default ScaleBar;
