import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToggleButtonGroup.module.css';

const ToggleButtonGroup = ({ buttonGroup }) => {
  return (
    <div className={styles.toggleContainer}>
      <div className={styles.toggleWrapper}>
        {buttonGroup.map(({ label, selected, onClick }) => (
          <button
            key={label}
            onClick={onClick}
            className={`${styles.button} ${selected ? styles.selected : ''}`}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

ToggleButtonGroup.propTypes = {
  buttonGroup: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default ToggleButtonGroup;
